const isPassValid = (pass: string) => {

    let rtn = {
        result: false,
        message: '',
    }

    if ( String(pass.trim()).length < 13 ) {
        rtn.message = '13文字以上のパスワードを設定してください';
        return rtn;
    }

    if ( String(pass.trim()).length >= 32 ) {
        rtn.message = '32文字以下のパスワードを設定してください';
        return rtn;
    }

    if ( ! /^([a-zA-Z0-9!-/:-@¥[-`{-~])+$/.test(pass) ) {
        rtn.message = 'パスワードの形式を確認してください';
        return rtn;
    } 

    if ( ! /^(?=.*?[a-zA-Z])/.test(pass) ) {
        rtn.message = 'パスワードは英字を最低１文字含ませてください';
        return rtn; 
    }

    if ( ! /^(?=.*?[!-/:-@¥[-`{-~])/.test(pass) ) {
        rtn.message = 'パスワードは記号を最低１文字含ませてください';
        return rtn;  
    }

    if ( ! /^(?=.*?\d)/.test(pass) ) {
        rtn.message = 'パスワードは数字を最低１文字含ませてください';
        return rtn;
    }

    rtn.result = true;
    return rtn;

}

export default isPassValid;