import { Fragment } from 'react';

import styles from 'styles/Linkbox.module.scss';
import { ReactComponent as KanriIcon } from 'images/cog-solid.svg';
import { ReactComponent as ShiftIcon } from 'images/calendar-alt-regular.svg';

import urlGenerator from 'functions/urlGenerator';
import isInWhiteList from 'functions/isInWhiteList';


const Linkbox: React.FC<{ dest: string, top_position: string, left_position: string, idt: any }> = ( props ) => {

    let url = urlGenerator( props.dest, props.idt ).url;
    let color: string = '';
    let title: string = '';
    let icon: JSX.Element = <Fragment></Fragment>;

    switch ( props.dest ) {

        case 'shift':
            color = '#d2b723';
            title = 'シフト管理';
            icon = <ShiftIcon 
                        color='#FFFFFF'
                        height='40' 
                        width='40' 
            />;
            break

        case 'kanri':
            color = '#677586';
            title = '管理者設定';
            icon = <KanriIcon 
                        color='#FFFFFF'
                        height='40' 
                        width='40' 
            />;
            break

        default:
            console.warn(`Unexpected value is passed: ${ props.dest }`)
            break         
    }


    const BoxContent = () => {
        return (
            <Fragment>
                <div 
                    className={ styles.circle }
                    style={{ backgroundColor: `${ color }` }}
                    >{ icon }
                </div>
                <p 
                    className={ styles.textUnderIcon }
                    style={{ color: `${ color }` }}
                    >{ title }
                </p>
            </Fragment>
        )
    };


    const redirectOnclickHandler = ( URL: string | undefined ) => {
        if ( !URL ) {
            return
        }

        if ( isInWhiteList(URL, props.idt).result === true ) {
            window.location.assign(URL);
        }
        return
    };


    return (
        <div 
            className={ styles.linkboxSquare }
            style={{
                position: 'absolute',
                top: `${ props.top_position }`,
                left: `${ props.left_position }`,
            }}
            onClick={ () => { redirectOnclickHandler(url); } }
            >
            <BoxContent />
        </div>
    )
};

export default Linkbox;