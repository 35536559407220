import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from 'styles/SideNav.module.scss';
import { ReactComponent as HomeIcon } from 'images/home-solid.svg';
import { ReactComponent as ArrowCircleLeft } from 'images/arrow-circle-left-solid.svg';
import { ReactComponent as ArrowCircleRight } from 'images/arrow-circle-right-solid.svg';
import { ReactComponent as ShiftIcon } from 'images/calendar-alt-regular.svg';
import { ReactComponent as KanriIcon } from 'images/cog-solid.svg';
import { ReactComponent as Envelop } from 'images/envelope-regular.svg';

import urlGenerator from 'functions/urlGenerator';
import getAsOfficePermissions from 'functions/getAsOfficePermissions';
import isInWhiteList from 'functions/isInWhiteList';

const SideNav: React.FC<{ user: any, signOut: any }> = ( props ) => {

    const idt = props.user.signInUserSession.idToken;
    const payload = idt.payload;
    const officeNum = payload.office_number;
    const navigate = useNavigate();

    // App links display be office permission
    const [ isShiftAllowed, setIsShiftAllowed ] = useState<boolean>(false);

    // App link display by user permission
    const adminUserPrivileges: string[] = ["0", "1"];
    const shiftUserPrivileges: string[] = ["0", "1", "3"];
    let isAdminUser = adminUserPrivileges.includes(payload.privilege);
    let isShiftAllowedUser = shiftUserPrivileges.includes(payload.privilege);

    useEffect(() => {
        const mainProcess = async () => {
            try {
                const perms = await getAsOfficePermissions( officeNum, idt )
                setIsShiftAllowed( perms.isShiftAllowed );
            } catch(e) {
                console.error(e);
            }
        }
        mainProcess();
    }, []);

    // Sidenav icons when closed
    const sidenavDefaultItems = (
        <Fragment>
            <div 
                className={ styles.icons }
                style={{ background: '#2396D2' }}
                >
                <div className={ styles.icon }>
                    <HomeIcon color="#BBC2CC" width="24"/>
                </div>
            </div>

            { 
                (isShiftAllowedUser && isShiftAllowed) ?
                    <div className={ styles.icons }>
                        <div className={ styles.icon }>
                            <ShiftIcon color="#BBC2CC" width="24"/>
                        </div>
                    </div>
                :
                    <></>
            }

            <div className={ styles.line }><hr></hr></div>

            { 
                isAdminUser ?
                    <div className={ styles.icons }>
                        <div className={ styles.icon }>
                            <KanriIcon color="#BBC2CC" width="24"/>
                        </div>
                    </div>
                :
                    <></>
            }

            <div className={ styles.icons }>
                <div className={ styles.envelopIcon}>
                    <Envelop fill="#BBC2CC" width="24"/>
                </div>
            </div>

            <div className={ styles.icons }>
                <div className={ styles.arrowIconRight }>
                    <ArrowCircleRight color="#BBC2CC" width="24"/>
                </div>
            </div>
        </Fragment> 
    );


    const [ sidenavItems, setSidenavItems ] = useState<JSX.Element>(sidenavDefaultItems);
    const SHIFT_URL = urlGenerator( 'shift', idt ).url;
    const KANRI_URL = urlGenerator( 'kanri', idt ).url;

    const redirectOnclickHandler = ( URL: string | undefined ) => {
        if ( !URL ) { return; }

        if ( isInWhiteList(URL, idt).result === true ) {
            window.location.assign(URL);
        }
        return;
    };

    // Sidenav icons when opened
    const sidenavEnterHandler = () => {
        setSidenavItems(
            <Fragment>
                <div 
                    className={ styles.openedSideNavHoverBlock }
                    style={{ background: '#2396D2' }}
                    >
                    <div className={ styles.openedIcon }>
                        <HomeIcon color="#BBC2CC" width="24"/>
                    </div>
                    <p
                        className={ styles.openedText }
                        onClick={() => navigate('/')}>
                            HOME</p>
                </div>

                {
                    (isShiftAllowedUser && isShiftAllowed) ?
                        <div 
                            className={ styles.openedSideNavHoverBlock }
                            onClick={ () => { redirectOnclickHandler(SHIFT_URL); } }
                            >
                            <div className={ styles.openedIcon }>
                                <ShiftIcon color="#BBC2CC" width="24"/>
                            </div>
                            <p className={ styles.openedText }>シフト管理</p>
                        </div>
                    :
                        <></>
                }

                <div className={ styles.line }><hr></hr></div>

                {
                    isAdminUser ?
                        <div 
                            className={ styles.openedSideNavHoverBlock }
                            onClick={ () => { redirectOnclickHandler(KANRI_URL); } }
                            >
                            <div className={ styles.openedIcon }>
                                <KanriIcon color="#BBC2CC" width="24"/>
                            </div>
                            <p className={ styles.openedText }>管理者設定</p>
                        </div>
                    :
                        <></>
                }

                <div className={ styles.openedArrowIconLeft }>
                    <ArrowCircleLeft color="#BBC2CC" width="24"/>
                </div>

                <div className={ styles.openedInquiryHoverBlock }>
                    <div className={ styles.openedEnvelop }>
                        <Envelop fill="#BBC2CC" width="24"/>
                    </div>
                    <a 
                        className={ styles.openedInquiryText }
                        target="_blank"
                        href="https://enq.konicaminolta.com/n/form/yjlb/LWH7HD-ruFBbs8eA3tXrK"
                            >お問い合わせ
                    </a>
                </div>

            </Fragment>   
        )
        return; 
    };
    
    const sidenavLeaveHandler = () => {
        setSidenavItems(sidenavDefaultItems)
        return;
    };




    return (
        <div 
            className={ styles.sidenav }
            onMouseEnter={ () => sidenavEnterHandler() }
            onMouseLeave={ () => sidenavLeaveHandler() }>
            { sidenavItems }
        </div>
    );
}

export default SideNav;