import { useState, useEffect, Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import styles from 'styles/Help.module.scss';
import faqStructure from 'confs/faqStructure.json';
import videoLinks from 'confs/manualVideoLinks.json';
import pdfLinks from 'confs/manualPdfLinks.json';

import postLog from 'functions/postLog';

interface faqStructureObj {
    title: string;
    children: { 
        title: string;
        children: { 
            title: string;
            routeId: string; 
        }[];
    }[];
}
interface faqDeepestObj {
    title: string;
    routeId: string;
    contents: string;   
}

const Help:React.FC<{ user: any }> = ( props ) => {

    const idt = props.user.signInUserSession.idToken;
    const confs: faqStructureObj[] = faqStructure.data;
    const [ faqPosts, setFaqPosts ] = useState<faqDeepestObj[]>([]);

    // React bootstrap componentsにCSSをうまく反映できないためここで設定
    const cardTitleFontSize = 24;
    const cardContentFontSize = 20;

    useEffect(() => {

        const findDeepestNodes = ( data: faqStructureObj[] ): faqDeepestObj[] => {

            let result: faqDeepestObj[] = [];

            const traverse = ( node: any ) => {

                // Check if this node is the deepest
                if ( !node.children || node.children.length === 0 ) {
                    if ( !node.title || !node.routeId ) { return; }
                    result.push({ title: node.title, routeId: node.routeId, contents: "" });
                } else {
                    node.children.forEach((child: any) => { traverse(child) })
                }
            };

            // Initial nest
            data.forEach((child) => { traverse(child) });
            return result;
        };

        const getContentsFromMdFiles = async (objs: faqDeepestObj[]) => {

            const promises = objs.map( async (obj) => {
                const fileName = obj.title + '.md';

                let markdownText: string;
                try {

                    const md = await import(`faqs/${ fileName }`);
                    const response = await fetch(md.default);
                    if ( !response.ok) {
                        throw new Error(`Failed to fetch Markdown file. Status: ${response.status}`);
                    }

                    markdownText = await response.text();
                    
                } catch(e) {
                    console.error(e);
                    return;
                }
                return markdownText
            })
            const resolved = await Promise.all(promises);
            return resolved;
        }

        const deepests = findDeepestNodes(confs);
        const promises = getContentsFromMdFiles(deepests);
        promises.then(( contents ) => {

            if ( contents.length !== deepests.length ) { console.error('contents and deepests length differs'); return; }

            let i = 0;

            contents.forEach((content) => {
                if ( typeof content === 'undefined' ) { i++; return }
                deepests[i].contents = content;
                i++
            });
            setFaqPosts(deepests);
        })

    }, []);


    const faqCardHandler = () => {
        
        // それぞれconfの1階層目、2階層目、3階層目
        let elem: JSX.Element[] = [];
        let elemY: JSX.Element[] = [];
        let elemZ: JSX.Element[] = [];
        
        confs.forEach((x) => {
            if ( typeof x.title === 'undefined' ) { return }

            // If 2nd layer exists iterate over
            if ( Array.isArray(x.children) ) {
                x.children.forEach((y) => {
                    if ( typeof y.title === 'undefined' ) { return }

                    // If 3rd layer exists iterate over
                    if ( Array.isArray(y.children) ) {
                        y.children.forEach((z) => {
                            if ( typeof z.title === 'undefined' || typeof z.routeId === 'undefined' ) { return }

                            const dynamicPath = `/help/${ z.routeId }`
                            elemZ.push(
                                <Fragment key={ z.title + '---z' }>
                                    <Accordion.Body
                                        onClick={() => {

                                            const actionLog = {
                                                data: {
                                                    action_faqAccessed_isProcessed: 1,
                                                    action_faqAccessed_faqId: z.routeId,
                                                    action_faqAccessed_faqTitle: z.title,
                                            }}
                                            postLog( actionLog, idt );
                                            
                                        }}>
                                        <Link to={ dynamicPath }>{ z.title }</Link>
                                    </Accordion.Body>
                                </Fragment>
                            )                     
                        })
                    }
                    
                    elemY.push(
                        <Fragment key={ y.title + '---y' }>
                            <Accordion.Item eventKey={ y.title + '---y' }>
                                <Accordion.Header>
                                    <div className={ styles.faq__accordionButton }>{ y.title }</div>
                                </Accordion.Header>
                                { elemZ }
                            </Accordion.Item>
                        </Fragment>
                    ); elemZ = [];
                })
            }

            elem.push(
                <Fragment key={ x.title + '---x' }>
                    <Accordion.Item eventKey={ x.title + '---x' }>
                        <Accordion.Header>
                            <div className={ styles.faq__accordionButton }>{ x.title }</div>
                        </Accordion.Header>
                        <Accordion.Body>{ elemY }</Accordion.Body>
                    </Accordion.Item>
                </Fragment>
            ); elemY = [];
        })

        return (
            <Card className={ `${styles.helpCard} ${styles.faqPosition}` }>
                <Card.Title style={{ fontSize: cardTitleFontSize }}>よくあるご質問</Card.Title>
                <Accordion alwaysOpen flush style={{ fontSize: cardContentFontSize }}>{ elem }</Accordion>
            </Card>
        )
    };


    const videolinkCardHandler = () => {

        let elem: JSX.Element[] = [];

        videoLinks.data.forEach(( videoLink ) => {
            
            if ( typeof videoLink.title === 'undefined' || typeof videoLink.url === 'undefined' ) { return; }
            if ( !videoLink.title || !videoLink.url ) { return; }

            elem.push(
                <ListGroup.Item 
                    key={ videoLink.title }
                    onClick={() => {

                        const actionLog = {
                            data: {
                                action_faqVideoManualAccessed_isProcessed: 1,
                                action_faqVideoManualAccessed_faqVideoTitle: videoLink.title,
                                action_faqVideoManualAccessed_faqVideoUrl: videoLink.url
                        }}
                        postLog( actionLog, idt );
                        
                    }}>
                    <a href={ videoLink.url } target="_blank">{ videoLink.title }</a>
                </ListGroup.Item>);
        });

        return (
            <Card className={ `${styles.helpCard} ${styles.videolinkPosition}` }>
                <Card.Title style={{ fontSize: cardTitleFontSize }}>使い方動画</Card.Title>
                <ListGroup 
                    bsPrefix="list-group-item border-0" 
                    style={{ fontSize: cardContentFontSize }}>{ elem }</ListGroup>
            </Card>
        );
    };

    const manualdownloadCardHandler = () => {
        // pdfは develop の miramos-manual という名のS3バケットに保管( バケットはpublic権限 )

        let elem: JSX.Element[] = [];

        pdfLinks.data.forEach(( pdfLink ) => {

            if ( typeof pdfLink.title === 'undefined' || typeof pdfLink.url === 'undefined' ) { return; }
            if ( !pdfLink.title || !pdfLink.url ) { return; }

            elem.push(
                <ListGroup.Item 
                    key={ pdfLink.title }
                    onClick={() => {

                        const actionLog = {
                            data: {
                                action_faqQuickGuideAccessed_isProcessed: 1
                        }}
                        postLog( actionLog, idt );

                    }}>
                    <a href={ pdfLink.url } target="_blank">{ pdfLink.title }</a>
                </ListGroup.Item>
            );
        });

        return (
            <Card className={ `${styles.helpCard} ${styles.manualdownloadPosition}` }>
                <Card.Title style={{ fontSize: cardTitleFontSize }}>マニュアルダウンロード</Card.Title>
                <ListGroup 
                    bsPrefix="list-group-item border-0" 
                    style={{ fontSize: cardContentFontSize }}>{ elem }</ListGroup>
            </Card>
        );
    };


    const routeHandler = () => {

        let routes: JSX.Element[] = [];

        if ( process.env.REACT_APP_DEBUG === 'True' ) {
            console.info('final faqPosts state: ', faqPosts);
        }

        if ( faqPosts.length < 1 ) { return; }

        faqPosts.forEach(( post ) => {
            const path = `/${ post.routeId }`;
            const elem = (
                <Fragment>
                    <Breadcrumb className={styles.faq__breadPosition}>
                        <Breadcrumb.Item linkAs={ Link } linkProps={{ to: "/help" }}>ヘルプ</Breadcrumb.Item>
                        <Breadcrumb.Item active>{ post.title }</Breadcrumb.Item>
                    </Breadcrumb>
                    <Card className={ `${styles.helpCard} ${styles.faq__contentPosition}` }>
                        <Markdown 
                            className={ styles.faq__markdown } 
                            remarkPlugins={[ remarkGfm ]}
                                >{ post.contents }</Markdown>
                    </Card>
                </Fragment>
            )
            routes.push(<Route key={path} path={path} element={ elem }></Route>)
        });

        return (
            <Routes>
                <Route path="/" element={
                    <Fragment>
                        <div>{ faqCardHandler() }</div>
                        <div>{ videolinkCardHandler() }</div>
                        <div>{ manualdownloadCardHandler() }</div>
                    </Fragment>
                }/>
                { routes }
            </Routes>
        );
    };

    return (
        <Fragment>
            { routeHandler() }
        </Fragment>
    );
};

export default Help;