import axios from 'axios';

interface respObj {
    officeName: string,
    status: number,
    messages: string,
}

const getAsOffice = async ( officeNum: string, idt: any ): Promise<respObj> => {

    let rtn = {
        officeName: '',
        status: 0,
        messages: '',
    };

    const jwt = idt.jwtToken;
    const KANRI_URL = process.env.REACT_APP_KANRI_URL + `/api/as_offices/?office_number=${ officeNum }&limit=1&with_deleted=0`;

    const res = await axios
        .get( KANRI_URL, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        })
    
    const d = res.data.datas[0];
    if ( process.env.REACT_APP_DEBUG === "True" ){
        console.info('response from as_offices: ', d);
    }    

    rtn.officeName = d.name;
    rtn.status = res.status;

    return rtn
}

export default getAsOffice