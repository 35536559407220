interface respObj {
    url: string,
    status: number,
    messages: string,
}

const urlGenerator = ( dest: string, idt: any ): respObj => {

    let rtn = {
        url: '',
        status: 0,
        messages: '',
    }

    const jwt = idt.jwtToken;

    let KANRI_URL = process.env.REACT_APP_KANRI_URL + '/?idt=' + jwt;
    let SHIFT_URL = process.env.REACT_APP_SHIFT_URL + '/?idt=' + jwt;

    
    // Output
    if ( dest === 'kanri' ) {
        if ( KANRI_URL ) { rtn.url = KANRI_URL; }
    } else if ( dest === 'shift') {
        if ( SHIFT_URL ) { rtn.url = SHIFT_URL; }
    } else {
        console.error('UrlGenerator received unexpected dest: ', dest);
    }
    
    return rtn;
};

export default urlGenerator;