import axios from 'axios';

interface respObj {
    isShiftAllowed: boolean,
    status: number,
    messages: string,
}

const getAsOfficePermissions = async ( officeNum: string, idt: any ): Promise<respObj> => {

    let rtn = {
        isShiftAllowed: false,
        status: 0,
        messages: '',
    };

    const jwt = idt.jwtToken;
    const KANRI_URL = process.env.REACT_APP_KANRI_URL + `/api/as_office_permissions/?office_number=${ officeNum }&with_deleted=0`;
    const res = await axios
                        .get( KANRI_URL, {
                            headers: { Authorization: `Bearer ${jwt}` }})
    
    const perms = res.data.datas;
    if ( process.env.REACT_APP_DEBUG === "True" ){
        console.info('response from as_offices_permissions: ', perms);
    }


    const checkIfStatusAllZero = ( perms: any, app_id: string ) => {

        let isAllZero = true;

        const permsByAppId = perms.filter((obj: { app_id: string, status: number }) => obj.app_id==app_id );
        const statusValues = permsByAppId.map((perm: { status: number; }) => perm.status);

        isAllZero = statusValues.every(( status: number ) => status===0);
        return isAllZero;
    }

    if ( checkIfStatusAllZero( perms, 'shift' ) === false ) {
        rtn.isShiftAllowed = true;
    }

    rtn.status = res.status;
    return rtn
}

export default getAsOfficePermissions