import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import getAsOffice from 'functions/getAsOffice';

import styles from 'styles/Header.module.scss';
import { ReactComponent as FacilIcon } from 'images/hospital-alt-solid.svg';
import { ReactComponent as UserCircleIcon } from 'images/user-circle-solid.svg';
import { ReactComponent as AngleUp } from 'images/angle-up-solid.svg';
import { ReactComponent as AngleDown } from 'images/angle-down-solid.svg';
import { ReactComponent as MiramosLogo } from 'images/miramos_logo_nosymbol.svg';
import { ReactComponent as Question } from 'images/circle-question-regular.svg';


const Header: React.FC<{ user: any, signOut: any }> = ( props ) => {

    const [ logoutModalOpen, setLogoutModalOpen ] = useState<boolean>(false);
    const [ facilityName, setFacilityName ] = useState<string>('');
    const navigate = useNavigate();

    const idt = props.user.signInUserSession.idToken;
    const jwt = idt.jwtToken;
    const payload = idt.payload;

    if ( process.env.REACT_APP_DEBUG === "True" ){
        console.info('cognitoUser is: ', props.user);
        console.info('idt is: ', idt);
        console.info('payload is: ', payload);
    }

    useEffect(() => {
        const officeNum = payload.office_number;

        const mainProcess = async () => {
            try {
                const officeData = await getAsOffice( officeNum, idt );
                setFacilityName( officeData.officeName );
            } catch(e) {
                console.error(e);
            }
        }
        mainProcess();
    }, []);

    const FacilityName = () => {
        return (
            <p>{ facilityName }</p>
        );
    };

    const UserName = () => {
        const username = payload.user_name;
        return ( <p>{ username }</p> )
    };

    const Angles = () => {
        let angle = <AngleDown color="#FFF" width='16'/>;
        if ( logoutModalOpen ) {
            angle = <AngleUp color="#FFF" width='16'/>;
        }
        return angle;
    };

    const logoutClickHandler = () => {
        localStorage.clear();
        props.signOut();
    };

    const LogoutModal = () => {
        let modal = <Fragment></Fragment>;
        if ( logoutModalOpen ) {
            modal = (
                <div className={ styles.logoutBlock }>
                    <div 
                        className={ styles.logoutTextBlock }
                        onClick={ () => logoutClickHandler() }
                        >
                        <div className={ styles.logoutText }>
                            <p>ログアウト</p>
                        </div>
                    </div>
                </div>
            )
        }
        return modal;
    }

    const usernameBlockClickHandler = () => {
        setLogoutModalOpen( prev => !prev );
    };

    return (
        <Fragment>

            <div className={ styles.miramosLogo }><MiramosLogo /></div>
            
            <div className={ styles.homeText }><p>| HOME</p></div>

            <div className={ styles.facilityNameBlock}>
                <div className={ styles.facilityIcon }>
                    <FacilIcon color="#BBC2CC" width='24'/>
                </div>
                <div className={ styles.facilityName }>
                    <FacilityName />
                </div>
            </div>

            <div 
                className={ styles.usernameHoverBlock }
                onClick={ () => usernameBlockClickHandler() }
                >
                <div className={ styles.userCircleIcon }>
                    <UserCircleIcon color="#BBC2CC" width='24'/>
                </div>
                <div className={ styles.userNameText }>
                    <UserName />
                </div>
                <div className={ styles.angleIcon }>
                    <Angles />
                </div>
            </div>

            <Fragment>
                <LogoutModal />
            </Fragment>
            {
            <div className={ styles.helpBlock }>
                <div className={ styles.helpIcon } onClick={() => navigate('/help')}>
                        <Question color="#BBC2CC" width='24'/>
                </div>
                <p
                    className={ styles.helpText }
                    onClick={() => window.open('/help', '_blank')}>ヘルプ
                </p>
            </div>
            }
        </Fragment>
    )
}

export default Header;