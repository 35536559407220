const isInWhiteList = ( url: string, idt: any ) => {

    let rtn = {
        result: false,
        messages: '',
    }

    let whiteList = [];

    const jwt = idt.jwtToken;
    whiteList.push(jwt);

    if ( whiteList.some(item => url.includes(item)) ) {
        rtn.result = true;
    } else {
        console.warn('a url not in whitelist!');
    }

    return rtn
}

export default isInWhiteList;