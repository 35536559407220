import { Fragment, useState, useEffect } from 'react';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';

import * as linkboxPositionsByNumberOfBoxes from 'confs/linkboxPositionsByNumberOfBoxes.json';

import getAsOfficePermissions from 'functions/getAsOfficePermissions';
import addTokensToLocalStorage from 'functions/addTokensToLocalStorage';
import postLog from 'functions/postLog';

import Help from 'components/Layout/Help';
import Linkbox from 'components/Layout/Linkbox';

interface linkboxPositionsObj {
    top: string;
    left: string    
}

const Body: React.FC<{ user: any, signOut: any }> = ( props ) => {

    const act = props.user.signInUserSession.accessToken.jwtToken;
    const idt = props.user.signInUserSession.idToken;
    const jwt = idt.jwtToken;
    const payload = idt.payload;

    // Icons display by office permission
    const [ isShiftAllowed, setIsShiftAllowed ] = useState(false);
    
    // Icons display by user permission
    const adminUserPrivileges = ["0", "1"];
    const shiftUserPrivileges = ["0", "1", "3"];
    let isAdminUser = adminUserPrivileges.includes(payload.privilege);
    let isShiftAllowedUser = shiftUserPrivileges.includes(payload.privilege);

    if ( process.env.REACT_APP_DEBUG === 'True' ) {
        console.info(`isAdminUser: ${isAdminUser}, privilege: ${payload.privilege}`)
    }

    // Adding tokens to storage
    const isUserSignedIn = localStorage.getItem('userSignedIn');
    if ( isUserSignedIn === 'yes' ) {
        addTokensToLocalStorage(jwt, act);
    }

    // For logout handling
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const officeNum = payload.office_number;
        const queryParams = new URLSearchParams(location.search);
        const logout = queryParams.get('logout');

        // handling log out flag from shift app
        if ( logout === '1' ) {
            localStorage.clear();
            navigate('/');
            props.signOut();
        }

        // handling logged-in log
        const isActionLogFlagOn = localStorage.getItem('action-log-loggedin-flag');
        if ( isActionLogFlagOn === 'yes' ) {

            const actionLog = {
                data: {
                    action_loggedIn_isProcessed: 1,
                }
            };
            postLog( actionLog, idt );
            localStorage.setItem( 'action-log-loggedin-flag', 'no' );
        }

        const mainProcess = async () => {
            try {
                const perms = await getAsOfficePermissions( officeNum, idt )
                setIsShiftAllowed( perms.isShiftAllowed );
            } catch(e) {
                console.error(e);
            }
        }
        mainProcess();
    }, []);


    const createLinkBoxes = (): JSX.Element => {

        let boxes = [
            {
                "boxName": "shift",
                "isAllowed": isShiftAllowedUser && isShiftAllowed,
            },
            {
                "boxName": "kanri",
                "isAllowed": isAdminUser,
            },
        ]

        const allowedBoxes = boxes.filter((obj) => {
            if ( obj.isAllowed === true ){
                return true;
            } else {
                return false;
            }
        })

        const numberOfBoxes = allowedBoxes.length
        const positions: linkboxPositionsObj[] = linkboxPositionsByNumberOfBoxes[numberOfBoxes as keyof object]

        let elem: JSX.Element[] = [];
        let i = 0;

        allowedBoxes.forEach((obj) => {
            const name = obj.boxName;
            const position = positions[i]
            elem.push(<Linkbox
                key={ name }
                dest={ name }
                top_position={ position.top }
                left_position={ position.left }
                idt={ idt } />)
            i++
        })
        return (<Fragment>{elem}</Fragment>)
    }
    return (
        <Fragment>
            <Routes>
                <Route path="/help/*" element={ <Help
                                                    user={ props.user }/> }/>
                <Route path="/" element={ createLinkBoxes() }/>
            </Routes>
        </Fragment>
    );
}

export default Body;