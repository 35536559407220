import axios from 'axios';

type actionLogObj = 
    | {
        data: {
            action_faqAccessed_isProcessed: number,
            action_faqAccessed_faqId: string,
            action_faqAccessed_faqTitle: string,
        }}
    | {
        data: {
            action_loggedIn_isProcessed: number,
        }}
    | {
        data: {
            action_faqQuickGuideAccessed_isProcessed: number,
        }}
    | {
        data: {
            action_faqVideoManualAccessed_isProcessed: number,
            action_faqVideoManualAccessed_faqVideoTitle: string,
            action_faqVideoManualAccessed_faqVideoUrl: string,
        }}

const postLog = async ( actionLog: actionLogObj, idt: any ) => {

    if ( process.env.REACT_APP_ENV === 'stage' ) { return; }

    let completeLog: any = {};
    completeLog = Object.assign( completeLog, actionLog );
    
    // Adding data
    try {
        const payload = idt.payload;
        completeLog.data.facility_id = payload.facility_id;
        completeLog.data.first_name = payload.first_name;
        completeLog.data.gender = payload.gender;
        completeLog.data.last_name = payload.last_name;
        completeLog.data.login_id = payload.login_id;       
        completeLog.data.office_number = payload.office_number;        
        completeLog.data.privilege = payload.privilege;
        completeLog.data.user_name = payload.user_name;   
    } catch (e) {
        console.error(e);
        return;
    }

    // Adding metadata
    //const jstNow = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));
    const jstNow = new Date().toLocaleString('jp-JP', { timeZone: 'Asia/Tokyo' });
    completeLog.data.timestamp_jst = jstNow;
    completeLog.data.application_name = "login-home";


    // Post Log
    const msg = JSON.stringify(completeLog);
    const jwt = idt.jwtToken;
    const ep = process.env.REACT_APP_PUTLOG_URL;
    if ( ep === undefined ) { return }

    const headers = { 
        'Authorization': jwt,
        'Content-Type': 'application/json' };

    const res = await axios
        .post( ep, msg, { headers: headers })
        .then( res => { 
            if ( process.env.REACT_APP_DEBUG === 'True' ) { console.log(res); }});
    return;
}

export default postLog;