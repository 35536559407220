const addTokensToLocalStorage = ( idt: string, act: string ) => {

    // Input validation
    if ( typeof idt !== 'string' || idt.length === 0 ){ console.error('wrong input idt: ', idt); return; }
    if ( typeof act !== 'string' || act.length === 0 ){ console.error('wrong input act: ', act); return; }

    if ( process.env.REACT_APP_DEBUG === "True" ){
        console.info('localStorage before adding: ', localStorage);
    }

    const obj = {
        "itToken": idt,
        "accessToken": act,
    };

    for ( const [ name, token ] of Object.entries( obj )) {
        localStorage.setItem( name, token );
    }

    if ( process.env.REACT_APP_DEBUG === "True" ){
        console.info('localStorage after adding: ', localStorage);
    }
    return;

};

export default addTokensToLocalStorage;